import React from 'react';
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'

const Contact = () => {
    return (
        <div className='page-contact'>
            <Header />
            <section className="tf-section page-title">
                <div className="container">
                    <div className="col-md-12">
                        <div className="page-title__body rm shw">
                            <div className="block-text pt-12">
                                <h2 className="sub-title mb-20">Contact</h2>
                                <p className="fs-24 mb-33" >Whether you want to integrate our games, <br /> advance your career or just want to have a chat with us. <br /><br /> Write to any of our dedicated addresses below.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-section contact">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <h1 className="heading-bg" data-aos="fade-in"><span>Mail</span></h1>
                                <h5 className="sub-title mb-10" data-aos="fade-up">Get in Touch</h5>
                                <h3 className="title mb-28" data-aos="fade-up">Send Us a Message for<br /> Any Reason</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className="col-md-6"> */}
                        <div className="contact-left">
                            <ul>
                                <li data-aos="fade-up">
                                    <div className="icon"><i className="fas fa-map-marker-alt"></i></div>
                                    <div className="info">
                                        <h5>Address</h5>
                                        <p className="fs-18">Science and Technology Park</p>
                                        <p className="fs-18">Aleksandra Medvedeva 2A, Niš, Serbia</p>
                                    </div>
                                </li>
                                <li data-aos="fade-up">
                                    <div className="icon"><i className="fas fa-envelope"></i></div>
                                    <div className="info">
                                        <h5>Sales</h5>
                                        <a className="fs-18" href="mailto:sales@redstone.rs" style={{ fontFamily: "inherit" }}>sales@redstone.rs</a>
                                    </div>
                                </li>
                                <li data-aos="fade-up">
                                    <div className="icon"><i className="fas fa-envelope"></i></div>
                                    <div className="info">
                                        <h5>General</h5>
                                        <a className="fs-18" href="mailto:info@redstone.rs" style={{ fontFamily: "inherit" }}>info@redstone.rs</a>
                                    </div>
                                </li>
                                <li data-aos="fade-up">
                                    <div className="icon"><i className="fas fa-envelope"></i></div>
                                    <div className="info">
                                        <h5>Career</h5>
                                        <a className="fs-18" href="mailto:career@redstone.rs" style={{ fontFamily: "inherit" }}>career@redstone.rs</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="col-md-6">
                            <form className="contact__form">

                                <div className="form-group">
                                    <input className="form-control" type="text" placeholder="Your name" required />
                                    <input type="email" className="form-control mr-0" id="exampleInputEmail1" placeholder="Your email" required />
                                </div>
                                <div className="form-group">
                                    <input className="form-control" type="text" placeholder="Subject" required />
                                    <select className="form-control mr-0" id="exampleFormControlSelect1">
                                        <option>Sales</option>
                                        <option>General</option>
                                        <option>Career</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Your message here"></textarea>
                                </div>
                                <button type="submit" className="btn-action style-2"><span>Send Now</span></button>
                            </form>
                        </div> */}
                    {/* </div> */}

                </div>

            </section>

            <section className="map" style={{ marginBottom: "80px" }}>
                <div className="container">
                    <div className="col-12">
                        {/* <p data-aos="fade-up"><iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.15830869428!2d-74.119763973046!3d40.69766374874431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a9b3117469%3A0xd134e199a405a163!2sT%C3%B2a%20nh%C3%A0%20Empire%20State!5e0!3m2!1svi!2s!4v1645507902092!5m2!1svi!2s" width="600" height="450"  allowFullScreen="" loading="lazy"></iframe></p> */}
                        <p data-aos="fade-up"><iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1528.524429112229!2d21.893743803265703!3d43.331312023934025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4755b70e519e3e47%3A0x570583fabd91361a!2sScience%20and%20Technology%20Park%20Ni%C5%A1!5e0!3m2!1sen!2srs!4v1655735714454!5m2!1sen!2srs" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe></p>
                    </div>
                </div>
            </section>

            {/* <Action /> */}
            <Footer />
            {/* <FooterStyle2 /> */}
        </div>
    );
}

export default Contact;