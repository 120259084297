const dataFaq = [
    {
        title: 'When the musics over turn off the light?',
        text: 'Oficia dese runt mollit anim id est labo met, conse ctetur adipi smod tempor inc ididunt ut labore et dolore qui inim veniam, quis nostrud ex ercitation ullamco laboris nisi ut aliquip',
        show: 'true'
    },
    {
        title: 'What is the best way to collect NFT?',
        text: 'Oficia dese runt mollit anim id est labo met, conse ctetur adipi smod tempor inc ididunt ut labore et dolore qui inim veniam, quis nostrud ex ercitation ullamco laboris nisi ut aliquip',

    },
    {
        title: 'Why NFT Trading is so popular?',
        text: 'Oficia dese runt mollit anim id est labo met, conse ctetur adipi smod tempor inc ididunt ut labore et dolore qui inim veniam, quis nostrud ex ercitation ullamco laboris nisi ut aliquip'
    },
    {
        title: 'How do you trade on Montono easily?',
        text: 'Oficia dese runt mollit anim id est labo met, conse ctetur adipi smod tempor inc ididunt ut labore et dolore qui inim veniam, quis nostrud ex ercitation ullamco laboris nisi ut aliquip'
    },
]

export default dataFaq;