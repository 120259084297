import React , { useState } from 'react';
// import { Link } from 'react-router-dom'

const Speciality = (props) => {

    const [datatext] = useState({
        subtitle: 'Our Focus',
        title: 'Amazing User Experience',
        desc: 'Our motto is no device left behind. This means that we understand the diversity of players and devices on the market and we strive to deliver best possible user experience and game performance on any hardware.'
    })

    const data = props.data;

    return (
        <section className="tf-section section-speciality">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-md-12">
                        <div className="block-tex pd-0">
                            <h5 className="sub-title mb-10" data-aos="fade-up">{datatext.subtitle}</h5>
                            <h3 className="title mb-28" data-aos="fade-up">{datatext.title}</h3>
                            <p className="fs-21" data-aos="fade-up">{datatext.desc}</p>
                        </div>
                    </div>
                    <div className="col-xl-7 col-md-12">
                        <div className="section-speciality__box">
                            {
                                data.map((data,index) => (
                                    <div key={index} className={`box-item bg-2 ${data.class} shw`} data-aos="fade-left">
                                        <h3 className='color-main'>{data.stt}</h3>
                                        <h5 className="h5">{data.title}</h5>
                                        <p>{data.desc}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Speciality;