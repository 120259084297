import React from 'react';
import { Link } from 'react-router-dom'
import { dataAction } from '../../assets/fake-data/data-action.js';


const Action = () => {

    return (
        <section className="tf-section action">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="action__body" data-aos="fade-up">
                            <div className="block-text">
                                <h3 className="mb-13">{dataAction.title}</h3>
                                <p className="fs-21 mb-7">{dataAction.desc}</p>
                            </div>
                            <Link to="/contact" className="btn-action style-2">{dataAction.buttonText}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Action;