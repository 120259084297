import React from 'react';
import CountUp from 'react-countup';
import dataGames from '../../assets/fake-data/data-games';

const getMonthDifference = (startDate, endDate) => {
    return (
        endDate.getMonth() -
        startDate.getMonth() +
        12 * (endDate.getFullYear() - startDate.getFullYear())
    );
};

const Counter = () => {

    const countGames =  Object.keys(dataGames).length;

    return (
        <section className="tf-section counter">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="counter__body" data-aos="fade-down">
                            <div className="counter">
                                <div className="number-counter">
                                    <CountUp end={countGames} />
                                </div>
                                <h5 className="title">Games Available</h5>
                            </div>
                            <div className="counter">
                                <div className="number-counter">
                                    <CountUp end={5} />
                                </div>
                                <h5 className="title">Regulated Markets</h5>
                            </div>
                            <div className="counter">
                                <div className="number-counter">
                                    <CountUp end={getMonthDifference(new Date('2021-07-01'), new Date(Date.now()))} />
                                </div>
                                <h5 className="title">Months In Production</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Counter;