import React from 'react';
import { Link } from 'react-router-dom'
import ScrollToTop from '../../ScrollToTop';

const Montono2 = (props) => {

    const data = props.data;

    return (
        <section className="tf-section montono" id='portfolio'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="block-text center">
                            <h1 className="heading-bg" data-aos="fade-in"><span>MORE</span></h1>
                            <h5 className="sub-title mb-10" data-aos="fade-up">More Games</h5>
                            <h3 className="title mb-28" data-aos="fade-up">Fun Never Ends</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row mt-52">
                    {
                        data.map((data, index) => (
                            <div key={index} className="col-xl-4 col-md-6">
                                <div className="image-box shw" data-aos="fade-up">
                                    <img src={data.img} alt="Monteno" />
                                    <div className="image-box__title" style={{ backgroundImage: `url${data.img}` }}>
                                        <Link to={`/games/${data.id}`} className="h6 fill-div" onClick={ScrollToTop}>More Info</Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

export default Montono2;