const dataCard = [
    {
        stt: '01',
        title: 'Blazing Performance',
        desc: 'In-house technologies for best in class performance.',
        class: 'mr-28',
        class2: '',
    },
    {
        stt: '02',
        title:'High Quality',
        desc: 'A beautiful blend of stunning graphics, animations and sounds.',
        class: 'mt-45',
        class2: 's2',
    },
    {
        stt: '03',
        title:'User Experience',
        desc: 'Thrilling gameplay with big prizes that keep you going.',
        class: 'mr-28 mt--16',
        class2: 's2-m',
    },
    {
        stt: '04',
        title:'Platform Support',
        desc: 'Mobile first. Without sacrifice in quality on traditional platforms.',
        class: 'mt-29',
        class2: 's2',
    },
]

export default dataCard;