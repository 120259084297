import item from './item';

const dataPortfolio = [
    {
        step: 'Lock&Cash',
        title: 'Mayan Coins',
        desc: 'Stunning visuals and huge prizes. Get all 15 coins in Lock&Cash bonus and score the massive GRAND prize!',
        profile: item.profileMayanCoins,
        id: 'mayan-coins'
    },
    {
        step: 'Expanding Wild',
        title: '5 Crown Fire',
        desc: 'A future classic with a bang. This fruit slot features Expanding Wild mechanic for triple the fun.',
        profile: item.profile5CrownFire,
        id: '5-crown-fire'
    },
    {
        step: 'Book Mechanics',
        title: 'Book Of Scorpio',
        desc: 'Explore a world of Egypt and riches beyond imagination. Book mechanics with stunning modern visuals.',
        profile: item.profileBookOfScorpio,
        id: 'book-of-scorpio'
    },
    {
        step: 'Expanding Wild, Bonus',
        title: 'Spooky Spins',
        desc: 'Get ready for a thrilling adventure with Spooky Spins! This Halloween-themed slot features expanding wilds and an exciting bonus game that will keep you on the edge of your seat!',
        profile: item.profileSpookySpins,
        id: 'spooky-spins'
    },
]

export default dataPortfolio;