import img1 from '../images/testimonial/testimonial-01.png'
import img2 from '../images/testimonial/testimonial-02.png'
import img3 from '../images/testimonial/testimonial-03.png'
import img4 from '../images/testimonial/testimonial-04.png'
import item from './item';

const dataTestimonials = [
    {
        img: img1,
        title: 'About REDSTONE',
        text: 'As more online slots from REDSTONE make their way onto the market, it will be interesting to see how they grow as a brand. The ones that they have produced thus far are definitely indicative of a company that could one day be at the top.',
        name: 'Vegas Slot Online',
        url: 'https://www.vegasslotsonline.com/redstone/'
    },
    {
        img: img2,
        title: 'About REDSTONE',
        text: 'The young supplier with experienced founders and hundreds of great ideas to implement. That’s how the REDSTONE slot provider can be described. The future is definitely bright for them.',
        name: 'SiGMA',
        url: 'https://sigma.world/directory/game-providers/redstone/'
    },
    {
        img: item.item13,
        title: 'About 50 Wild Cash',
        text: '50 Wild Cash is another classic fruit slot from REDSTONE with a contemporary twist. The best game developers know when to keep it simple, and 50 Wild Cash is a good example.',
        name: 'Slot Catalog',
        url: 'https://slotcatalog.com/en/slots/50-Wild-Cash'
    },
    {
        img: img3,
        title: 'About REDSTONE',
        text: 'Red Stone Gaming works tirelessly and passionately so that their current and potential fans can enjoy only the highest quality video slots, with a great atmosphere and cool gaming experience. Some of these newly released slots already resonated with a lot of people, and became extremely popular!',
        name: 'Slots Judge',
        url: 'https://slotsjudge.com/online-slots/red-stone-gaming'
    },
    {
        img: img4,
        title: 'About 40 Cash Bells',
        text: 'Fans of retro games will love this offering from gamemaker REDSTONE. It has lots of classic symbols and the wild bells symbol appears as a stack. With a bit of luck, you will land a few on a reel to help create multiple winning lines.',
        name: 'Slot Temple',
        url: 'https://www.slotstemple.com/free-slots/40-cash-bells/'
    },
]

export default dataTestimonials;