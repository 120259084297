const dataRoadMap = [
    {
        time: 'February, 2021',
        title: 'Foundation',
        desc: 'REDSTONE is founded and officially comes to life. Intensive work on game engine and first prototypes begins.',
        class: 'left'
    },
    {
        time: 'June, 2021',
        title: 'Market',
        desc: '40 Wild Clover hits the market as first REDSTONE game release. It is instantly well received by players.',
        class: 'right mt-223'
    },
    {
        time: 'April, 2022',
        title: 'Regulated Market',
        desc: 'REDSTONE acquires licenses for 2 major regulated markets for 10 games. Romania and Switzerland are now on the table.',
        class: 'left mt--23'
    },
    {
        time: 'August, 2022',
        title: 'Snowballing',
        desc: '17 game releases in under a year on the market. Available in more than 20 countries and 5 regulated markets.',
        class: 'right mt-200'
    },
]

export default dataRoadMap;