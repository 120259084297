import React from 'react';
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import dataCard from '../assets/fake-data/data-card';
import dataMontono from '../assets/fake-data/data-montono';
// import dataPartners from '../assets/fake-data/data-partners';
import dataPortfolio from '../assets/fake-data/data-portfolio';
import dataRoadMap from '../assets/fake-data/data-roadmap';
import dataTestimonials from '../assets/fake-data/data-testimonials';
import About from '../components/layouts/About';
import Action from '../components/layouts/Action';
import Montono from '../components/layouts/Montono';
// import Partners from '../components/layouts/Partners';
import Portfolio from '../components/layouts/Portfolio';
import RoadMap from '../components/layouts/RoadMap';
import Speciality from '../components/layouts/Speciality';
import Testimonial from '../components/layouts/Testimonial';
import SliderOne from '../components/slider/SliderOne';
import { Link } from 'react-router-dom';

const Home01 = () => {
    return (
        <div>
            <Header />
            <SliderOne />
            <About />
            <Speciality data={dataCard} />
            <Portfolio data={dataPortfolio} />
            <Montono data={dataMontono} />
            <div className="view-all center">
                <Link className="btn-action" to="/games">
                    View All Games
                </Link>
            </div>
            <RoadMap data={dataRoadMap} />
            {/* <Partners data={dataPartners} /> */}
            <Testimonial data={dataTestimonials} />
            <Action />
            <Footer />
        </div>
    );
}

export default Home01;