const dataAction = {
    title: 'Do you like our games?',
    desc: 'Get in touch with us.',
    buttonText: 'Connect',
};

const dataAction2 = {
    title: 'Looking for a job?',
    desc: 'Please send us a portfolio or use the cover letter to describe the projects you have worked on.',
    note: 'Only eligible applications will be processed.',
    buttonText: 'Apply Now',
}

const dataAction3 = {
    title: 'Apply for this job',
    desc: 'Please send us a portfolio and cover letter on our email: ',
    note: 'Only eligible applications will be processed.',
    buttonText: 'Apply Now',
}

export { dataAction, dataAction2, dataAction3 }