import item from './item'

const dataMontono2 = [
    {
        img: item.item61,
        id: 'spooky-spins',
    },
    {
        img: item.item48,
        id: 'caps-and-crowns',
    },
    {
        img: item.item64,
        id: '10-clover-fire',
    },
    {
        img: item.item1,
        id: 'mayan-coins',
    },
    {
        img: item.item42,
        id: 'chilli-double',
    },
    {
        img: item.item63,
        id: 'only-anime',
    },
    {
        img: item.item45,
        id: 'slot-royale',
    },
    {
        img: item.item6,
        id: 'lost-book',
    },
]

export default dataMontono2;