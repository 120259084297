
const dataPortfolio = [
    { bg: 'bg-2', },
    { bg: 'bg-1', },
    { bg: 'bg-2', },
    { bg: 'bg-1', },
    { bg: 'bg-2', },
    { bg: 'bg-1', },
    { bg: 'bg-2', },
]

export default dataPortfolio;