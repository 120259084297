import React , { useState } from 'react';
import { Link } from 'react-router-dom'
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import item from '../../assets/fake-data/item';

const SliderOne = () => {

    const [datatext] = useState({
        subtitle: 'We are REDSTONE',
        title: 'Enjoy Next Generation Today',
        desc: 'We build blazing fast slot games for any device and any screen size.'
    })

    return (
        <section className="tf-section hero-slider">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-md-6 col-12">
                        <div className="block-text pt-24" >
                            <h6 className="sub-title mb-6" data-aos="fade-up">{datatext.subtitle}</h6>
                            <h2 className="title mb-26" data-aos="fade-up">{datatext.title}</h2>
                            <p className="desc mb-43" data-aos="fade-up">{datatext.desc}</p>
                            <Link to="/games" className="btn-action style-2" data-aos="fade-up">OUR GAMES</Link>
                        </div>
                    </div>
                    <div className="col-xl-7 col-md-6 col-12">
                        <div className="content-right d-flex">
                        <Swiper
                                modules={[ Autoplay ]}
                                direction={"vertical"}
                                spaceBetween={30}
                                slidesPerView={3}
                                loop
                                allowTouchMove={false}
                                autoplay={{
                                    delay: 1,
                                    disableOnInteraction: false,
                                    pauseOnMouseEnter: true,
                                }}
                                speed= {2000}
                            >
                                <SwiperSlide><div className="item bg-1 shw"><img src={item.item9} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-1 shw"><img src={item.item1} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item2} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-1 shw"><img src={item.item3} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-1 shw"><img src={item.item22} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item4} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item38} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-1 shw"><img src={item.item5} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-1 shw"><img src={item.item12} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item17} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item25} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item51} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item23} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-1 shw"><img src={item.item36} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item20} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item46} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item34} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item49} alt="Monteno" /></div></SwiperSlide>

                            </Swiper>
                            <Swiper
                                modules={[ Autoplay ]}
                                direction={"vertical"}
                                spaceBetween={30}
                                slidesPerView={3}
                                loop
                                allowTouchMove={false}
                                autoplay={{
                                    delay: 1,
                                    reverseDirection: true,
                                    disableOnInteraction: false,
                                    pauseOnMouseEnter: true,
                                }}
                                speed= {2000}
                            >
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item6} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-1 shw"><img src={item.item37} alt="Monteno" /></div></SwiperSlide>                                
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item8} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item32} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item19} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item44} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item45} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item55} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item64} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-1 shw"><img src={item.item7} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item60} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item33} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-1 shw"><img src={item.item31} alt="Monteno" /></div></SwiperSlide>                                
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item10} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item61} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item27} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item47} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item28} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item52} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item35} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item62} alt="Monteno" /></div></SwiperSlide>
                            </Swiper>
                            <Swiper
                                modules={[ Autoplay ]}
                                direction={"vertical"}
                                spaceBetween={30}
                                slidesPerView={3}
                                loop
                                allowTouchMove={false}
                                autoplay={{
                                    delay: 1,
                                    disableOnInteraction: false,
                                    pauseOnMouseEnter: true,
                                }}
                                speed= {2000}
                            >
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item42 } alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item21} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item11} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item29} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item30} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item13} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item56} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item26} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-1 shw"><img src={item.item14} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item15} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item16} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item18} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item43} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item50} alt="Monteno" /></div></SwiperSlide>
                                <SwiperSlide><div className="item bg-2 shw"><img src={item.item63} alt="Monteno" /></div></SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default SliderOne;