import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';

const Testimonial = (props) => {

    const data = props.data;

    return (
        <section className="tf-section testimonials">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Swiper
                            modules={[Navigation, Scrollbar, A11y]}
                            spaceBetween={0}
                            navigation
                            slidesPerView={1}
                            scrollbar={{ draggable: true }}
                        >
                            {
                                data.map((data, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="testimonials-box">
                                            <div className="image">
                                                <img src={data.img} alt="Monteno" className="shw" />
                                            </div>

                                            <div className="block-text ">
                                                <h5 className="sub-title mb-10" >What People Say</h5>
                                                <h3 className="title">{data.title}</h3>
                                                <div className="message">

                                                    <div className="message-content" data-aos="fade-up">
                                                        <p className="text">{data.text}</p>

                                                        <div className="info">

                                                            <h6>{data.name},</h6>
                                                            <a href={data.url}
                                                                rel="norefferer noreferrer"
                                                                target="_blank"
                                                                className="fs-16">
                                                                <i className="fas fa-external-link-alt"
                                                                    style={{ color: "rgb(220, 52, 47)", margin: "0 8px" }}></i>Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;