import React from 'react';
import { useParams } from 'react-router-dom'
import dataJobs from '../assets/fake-data/data-jobs';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Action3 from '../components/layouts/Action3';
import CareerList from '../components/layouts/CareerList';
import dataCareer from '../assets/fake-data/data-career';

const Jobs = () => {

    const { id } = useParams();

    const selectedJob = dataJobs[id || Object.keys(dataJobs)[0]];

    return (
        <div className='page-faq'>
         <Header />
            <section className="tf-section page-title pt-12 pd-40">
                <div className="container">
                    <div className="col-md-12">
                        <div className="page-title__body rm">
                            <div className="block-text pt-12">
                                <h2 className="sub-title mb-20 fs-52">{selectedJob.name}</h2>
                                <p className="fs-24 pd-16">{selectedJob.desc}</p>
                                <h5>Be a part of a team where you will:</h5>
                                <ul className="bullet-points">
                                {
                                                selectedJob.list1.map((data, index) => (
                                                    <li key={index}>{data}</li>
                                                ))
                                }
                                </ul>
                                <br></br>
                                <h5>You have:</h5>
                                <ul className="bullet-points">
                                {
                                                selectedJob.list2.map((data, index) => (
                                                    <li key={index}>{data}</li>
                                                ))
                                }
                                </ul>
                                <br></br>
                                <h5>What do we offer:</h5>
                                <ul className="bullet-points">
                                {
                                                selectedJob.list3.map((data, index) => (
                                                    <li key={index}>{data}</li>
                                                ))
                                }
                                </ul>
                              {/* { selectedJob.name === 'Illustrator' ? <p className="pt-24">The deadline for your applications is October 31, 2022. Simply visit <a className="ft-color-r" href="https://mojaprvaplata.gov.rs/pozicije" target="_blank" rel="noreferrer">Moja prva plata</a>, choose “RED STONE GAMING” as a filter under “Companies” and send us your CV.</p> : '' } */}
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Action3 />
            <CareerList data={dataCareer}/>
            <Footer />
        </div >
    );
}

export default Jobs;