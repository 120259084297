import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
// import dataPartners from '../assets/fake-data/data-partners';
import About from '../components/layouts/About';
import Action from '../components/layouts/Action';
import Counter from '../components/layouts/Counter';
// import Partners from '../components/layouts/Partners';
import dataRoadMap from '../assets/fake-data/data-roadmap';
import RoadMap from '../components/layouts/RoadMap';
// import dataRoadMap from '../assets/fake-data/data-roadmap';
// import Team2 from '../components/layouts/Team2';

const AboutUs = () => {
    return (
        <div className='about'>
            <Header />
            <section className="tf-section page-title">
                <div className="container">
                    <div className="col-md-12">
                        <div className="page-title__body ab shw">
                            {/* <img src={img} alt="Monteno" /> */}
                            <div className="block-text pt-12">
                                <h2 className="sub-title mb-20">About Us</h2>
                                <p className="fs-24 mb-33" >
                                    We build thrilling slot games based on high performance technologies developed in-house.
                                    <br />
                                    Our games are made with mobile market in mind, lightweight but at no cost of quality.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <About />
            <Counter />
            <RoadMap data={dataRoadMap} />
            {/* <RoadMap2 data={dataRoadMap} /> */}
            {/* <Portfolio data={dataPortfolio} /> */}
            {/* <Team2 data={dataTeam} /> */}
            {/* <Partners data={dataPartners} /> */}
            <Action />
            <Footer />
        </div>
    );
};

export default AboutUs;