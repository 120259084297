import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from "swiper";
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import Montono2 from '../components/layouts/Montono2';
import Action from '../components/layouts/Action';
import dataMontono2 from '../assets/fake-data/data-montono2';
import dataGames from '../assets/fake-data/data-games';


const Games = () => {

    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const { id } = useParams();
    const selectedGame = dataGames[id || Object.keys(dataGames)[0]];

    return (
        <div className='games'>
            <Header />
            <section className="tf-section page-title">
                {/* <div className="container">
                    <div className="col-md-12">
                        <div className="page-title__body rm">
                            <div className="block-text pt-12">
                                <h2 className="sub-title mb-20">NFT</h2>
                                <p className="fs-24 mb-33" >Sed ut perspiciatis unde omnis iste natus <br /> error sit voluptatem accusantium </p>
                            </div>
                            <img className="s2" src={img} alt="Monteno" />
                        </div>
                    </div>
                </div> */}
            </section>

            <section className="tf-section collection montono-short">
                <div className="container">
                    <div style={{
                        backgroundImage: selectedGame.bg ? `url(${selectedGame.bg})` : ``,
                        backgroundRepeat: selectedGame.bg ? `no-repeat` : ``,
                        backgroundSize: selectedGame.bg ? `contain` : ``,
                    }}>
                        <div className="row">
                            <div className="col-12">
                                <div className="block-text center">
                                    <h1 className="heading-bg" data-aos="fade-in"><span>GAME</span></h1>
                                    <h5 className="sub-title mb-10" data-aos="fade-up">{!!id ? 'Game Info' : 'NEW RELEASE'}</h5>
                                    <h3 className="title mb-28 game-title" data-aos="fade-up">{selectedGame.name}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-50">
                            <div className="col-xl-7 col-md-12">
                                <div className="group-image">

                                        <Swiper
                                            style={{
                                                "--swiper-navigation-color": "#fff",
                                                "--swiper-pagination-color": "#fff",
                                            }}
                                            spaceBetween={10}
                                            navigation={true}
                                            thumbs={{ swiper: thumbsSwiper }}
                                            modules={[FreeMode, Navigation, Thumbs]}
                                            className="gallery-top"
                                        >
                                            {
                                                selectedGame.imgs.map((data, index) => (
                                                    <SwiperSlide key={index}>
                                                        <div className="item bg-2" style={{ backgroundImage: `url(${data})`, backgroundSize: "cover" }}></div>
                                                    </SwiperSlide>
                                                ))
                                            }
                                        </Swiper>
                                        <Swiper
                                            onSwiper={setThumbsSwiper}
                                            spaceBetween={10}
                                            slidesPerView={4}
                                            freeMode={true}
                                            watchSlidesProgress={true}
                                            modules={[FreeMode, Navigation, Thumbs]}
                                            className="gallery-thumbs"
                                        >
                                            {
                                                selectedGame.imgs.map((data, index) => (
                                                    <SwiperSlide key={index}>
                                                        <div className="item bg-2" style={{ backgroundImage: `url(${data})`, backgroundSize: "cover" }}></div>
                                                    </SwiperSlide>
                                                ))
                                            }
                                        </Swiper>

                                    </div>
                                </div>
                                <div className="col-xl-5 col-md-12">
                                    <div className="block-text">
                                        <h3 className="fs-42 mb-31" id="game-title">About The Game</h3>
                                        <p className="fs-18">{selectedGame.desc}</p>
                                        <a href={selectedGame.url} rel="norefferer noreferrer" target="_blank" className="btn-action style-2" data-aos="fade-up" style={{ marginTop: "18px" }}>PLAY GAME</a>
                                        <h3 className="fs-30 mt-37">Features</h3>
                                        <ul className="nft">
                                            {
                                                selectedGame.features.map((data, index) => (
                                                    <li key={index}><Link to="#"><i className="fa fa-arrow-right"></i><span>{data}</span></Link></li>
                                                ))
                                            }
                                            {/* <li><Link to="#"><i className="fa fa-arrow-right"></i><span>Lock&Cash bonus</span></Link></li>
                                    <li><Link to="#"><i className="fa fa-arrow-right"></i><span>GRAND prize</span></Link></li>
                                    <li><Link to="#"><i className="fa fa-arrow-right"></i><span>Wild Symbol</span></Link></li>
                                    <li><Link to="#"><i className="fa fa-arrow-right"></i><span>10 Lines</span></Link></li> */}
                                        </ul>
                                        {/* <h3 className="fs-30 mt-19">Links</h3>
                                <ul className="nft s2">
                                    <li><Link to="#"><i className="fas fa-external-link-alt"></i><span>Information & Rules</span></Link></li>
                                </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Montono2 data={dataMontono2} />
            <Action />
            <Footer />
        </div >
    );
}

export default Games;