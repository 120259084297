import React from 'react';
import { Link } from 'react-router-dom';
import ScrollToTop from '../../ScrollToTop';

const CareerList = (props) => {

    const data = props.data;

    return (  
                <div className="container">
                    <div className="row mt-52 pd-60">
                        {data.map((data, index) => (
                            <div key={index} className="col-xl-4 col-md-6">
                                <div className="image-box shw" data-aos="fade-up">
                                    <img src={data.img} alt="Monteno" />
                                    <div className="image-box__title">
                                        <Link to={`/career/${data.id}`} className="h6 fill-div" onClick={ScrollToTop}>More info</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
    );
}

export default CareerList;
