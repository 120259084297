

const dataJobs = {
    'programmer': {
        name: 'Programmer',
        desc: 'If you have a passion for coding, join our fast-growing development studio and help us to deliver high-quality video slot games for global markets as a Software Developer - Game Developer in Niš, Serbia.',
        list1: [
            'Design and implement engaging game features for an online slot gaming platform, focusing on gameplay.',
            'Develop visually compelling and interactive components to enhance gameplay, ensuring seamless and responsive design across devices.',
            'Write optimized, maintainable JavaScript code that is efficient and easy to understand, enhancing collaboration within the team.',
            'Collaborate closely with backend developers to integrate game mechanics and data in a smooth, cohesive experience.',
            'Maintain thorough documentation to support game features and updates.',
            'Adapt to and integrate with new game development tools and frameworks as the project evolves.',
            'Perform all other duties as assigned.',
        ],
        list2: [
            'Educational background in Computer Science, Engineering or related field.',
            '3+ years of professional software development experience is required.',
            'Being precise, detailed and accurate.',
            'Proactive and a team player (cooperating with other teams and having a curious mindset).',
            'Previous experience in a similar position.',
            'Previous experience in the video games or igaming industry.',
        ],
        list3: [
            'Full time contract.',
            'Modern working tools and equipment.',
            '20 days of paid vacation per year.',
            'Monthly allowance for transportation.',
            'Fun work start-up environment.',
            'Becoming part of a team where you can make a difference/impact from day one.',
            'Many other privileges and benefits.'
        ],
    },
    // 'programmer': {
    //     name: 'Programmer',
    //     desc: 'If you have a passion for coding, join our fast-growing development studio and help us to deliver high-quality video slot games for global markets as a Software Developer - Tools Engineer in Niš, Serbia.',
    //     list1: [
    //         'Design and develop software systems using scientific analysis and mathematical models to predict and measure outcomes and design consequences.',
    //         'Develop in-house software with mathematical functionality in mind.',
    //         'Build out new features from the ground up by writing clean, scalable code.',
    //         'The technologies you primarily work on are Java Script, HTML5, CSS, React and Typescript.',
    //         'Directing software programming and documentation development.',
    //         'All other duties as assigned.',
    //     ],
    //     list2: [
    //         'Educational background in Computer Science, Engineering or related field.',
    //         '5+ years of professional software development experience is required.',
    //         'Experience in mathematics, statistics, software engineering or similar.',
    //         'Mathematical knowledge of statistics and probability is a must.',
    //         'Proficiency in Microsoft Excel.',
    //         'Being precise, detailed and accurate.',
    //         'Proactive and a team player (cooperating with other teams and having a curious mindset).',
    //         'Previous experience in a similar position.',
    //         'Previous experience in the video games or igaming industry.',
    //     ],
    //     list3: [
    //         'Full time contract.',
    //         'Modern working tools and equipment.',
    //         '20 days of paid vacation per year.',
    //         'Monthly allowance for transportation.',
    //         'Free meal.',
    //         'Fun work start-up environment.',
    //         'Becoming part of a team where you can make a difference/impact from day one.',
    //     ],
    // },
    '2d-animator': {
        name: '2D Animator',
        desc: 'Join our fast growing development studio in building high quality video slot games for global markets as a 2D Animator in Niš, Serbia. As a 2D Animator, you will develop storyboards and symbol, background and character animations.',
        list1: [
            'Create animations that are both fluid and responsive in Dragon Bones, Spine or Adobe After Effects.',
            'Controll the quality and optimization of animation assets.',
            'Continually iterating and improving animations that are consistent with the overall game.',
            'Work closely with the development team to create an excellent gameplay experience.',
            'Find creative art solutions for the animation challenges.',
        ],
        list2: [
            'Solid understanding of movement, anticipation, squash and stretch, and other principles of animation.',
            'Training in 2D animation, both cell and computer graphics.',
            'Ability to bring innovative solutions to technical problems and challenges.',
            'Passion for video games and game development.',
            'Familiarity with art production and pipelines.',
            'Appreciation and understanding of teamwork.',
            'Visual storytelling.',
            'Ability to draw in a variety of styles and genres.',
            'Upper intermediate English level.',
            'Previous experience in the video games or iGaming industry is a bonus.',
        ],
        list3: [
            'Full time contract.',
            'Modern working tools and equipment.',
            '20 days of paid vacation per year.',
            'Monthly allowance for transportation.',
            'Fun work start-up environment.',
            'Becoming part of a team where you can make a difference/impact from day one.',
            'Many other privileges and benefits.'
        ],
    },
    // 'account-manager': {
    //     name: 'Account Manager',
    //     desc: 'Join our fast-growing development studio in promoting high-quality video slot games for global markets as an Account Manager in Niš, Serbia. As an Account Manager you will represent the company and games at industry events, communicate with clients and be the first point of contact.',
    //     list1: [
    //         'Be responsible for developing the existing brand and increasing its overall performance.',
    //         'Contact clients regularly through phone, email, Skype and face-to-face.',
    //         'Manage close and long lasting relationships with clients and shareholders.',
    //         'Serve as the main point of contact in all matters related to client concerns and needs.',
    //         'Manage social media accounts and post relevant content about new games, regularly.',
    //         'Help manage our games on clients websites, ensuring that the games work properly, positions, logos, etc.',
    //         'Travel and represent the company on various trade shows and expos such as SBC, iGB, SiGMA, etc',
    //         'Cooperate with the Art and Development department inside the company.',
    //     ],
    //     list2: [
    //         `Bachelor's degree in Communication, Management, Economics or related field;`,
    //         'Excellent communication and interpersonal skills.',
    //         'Previous experience in a similar position, in the B2B or iGaming industry.',
    //         'Fully proficient in English (any other foreign language will be an advantage).',
    //         'Great organization, planning and prioritization skills, with strong attention to detail.',
    //         'Self-driven, responsible and detail-oriented person who can multi-task and deliver high quality work on time.',
    //         'Visual storytelling.',
    //         'Ability to draw in a variety of styles and genres.',
    //         'Upper intermediate English level.',
    //         'Computer literacy with good knowledge of MS office applications.',
    //     ],
    //     list3: [
    //         'Full time contract.',
    //         'Modern working tools and equipment.',
    //         '20 days of paid vacation per year.',
    //         'Monthly allowance for transportation.',
    //         'Free meal.',
    //         'Free soft drinks.',
    //         'Fun work start-up environment.',
    //         'Becoming part of a team where you can make a difference/impact from day one.',
    //     ],
    // },
    'designer-illustrator': {
        name: 'Designer Illustrator',
        desc: 'Join our fast-growing development studio in building high-quality video slot games for global markets as a Graphic Designer in Niš, Serbia.',
        list1: [
            'Create sets of static and animated banners and thumbnails in multiple formats featuring our new games and promotions.',
            'Contribute to team efforts by accomplishing tasks as needed.',
            'Finalizing design work with software such as Illustrator, Photoshop and-or Affinity Photo.',
            'Maintain awareness of current industry and technology standards, social media, competitive landscape, and market trends.',
            'Create compelling social media posts and thumbnails.',
        ],
        list2: [
            '2+ years experience in graphic design — agency side, in-house or freelance considered.',
            'A complete portfolio of graphic and digital design work.',
            'Knowledge of layouts, graphic fundamentals, typography, print, and web design.',
            'Experience in using varied design software and technology, including Photoshop, Illustrator, After Effects, Affinity Photo, and Affinity Designer.',
            'Strong ability to work at pace whilst still achieving a high level of accuracy.',
            'Superior attention to detail.',
            'Previous experience in a similar position and/or experience in the video games or igaming industry are a bonus.',
        ],
        list3: [
            `The practice will be implemented with the help of an experienced illustrator
            from Monday to Friday 8 hours per day. It will be conducted live in the
            company's premises with the constant support and supervision of a mentor;`,
            'All necessary equipment will be provided.',
            'You will acquire new and master existing skills, such as the ability to draw in different styles and the ability to create digital illustrations.',
            'You will improve their knowledge in the field of graphics, about the concepts of colors, shapes, proportions, perspectives, as well as the knowledge of human anatomy that is important for creating characters.',
        ],
    },
    // 'illustrator': {
    //     name: 'Illustrator',
    //     desc: 'If you are a talented artist who loves to draw and paint, join our fast-growing development studio in building high-quality video slot games for global market as an Junior Illustrator in Nis, Serbia, where you will create backgrounds, symbols and game items. ',
    //     list1: [
    //         'Prepare and create illustration drafts in accordance with business and market needs',
    //         'Create illustrations by digital drawing',
    //         'Illustrate in different styles',
    //         'Create characters, symbols and backgrounds for online slot games',
    //         'Collaborate with design and development departments',
    //         'Work closely with animators',
    //     ],
    //     list2: [
    //         'Bachelor degree of Arts and you are under 30 years of age',
    //         'Basic knowledge of digital illustration',
    //         'Analytical skills',
    //         'Orientation on details',
    //         'High motivation',
    //         'Team player',
    //     ],
    //     list3: [
    //         `The practice will be implemented with the help of an experienced illustrator
    //         from Monday to Friday 8 hours per day. It will be conducted live in the
    //         company's premises with the constant support and supervision of a mentor;`,
    //         'All necessary equipment will be provided.',
    //         'You will acquire new and master existing skills, such as the ability to draw in different styles and the ability to create digital illustrations.',
    //         'You will improve their knowledge in the field of graphics, about the concepts of colors, shapes, proportions, perspectives, as well as the knowledge of human anatomy that is important for creating characters.',
    //     ],
    // },
    'mathematician': {
        name: 'Mathematician',
        desc: 'Join our fast-growing development studio in building high-quality video slot games for global markets as a Mathematician in Niš, Serbia.',
        list1: [
            'Utilize your mathematical expertise and become an indispensable member of a dynamic team that values your knowledge. As a distinguished mathematician specialized in combinatorics and probability, you will have a pivotal role in revolutionary projects related to slot games, contributing to innovation reaching its peaks.',
            'The ability to approach combinatorial problems innovatively will bring originality to the creation of slot games.',
            'As a key team member, you will share your knowledge with new generations of mathematicians in the gaming industry. Simultaneously, you will have the opportunity to deepen your skills through the application of exciting concepts in the world of slot games.',
            'The analytical approach you engage in will optimize the player experience through careful adjustment of probabilities and payouts.',
            'Your inclination for collaboration and clear communication will allow you to connect mathematical concepts with design, programming, and user experience',
        ],
        list2: [
            'Exceptional organizational skills.',
            'Self-driven, responsible and detail-oriented person who can multi-task and deliver high quality work on time.',
            'Experience with Google Sheets and Excel spreadsheets.',
            'Constant desire for the development of your skills.',
            'Profound analytical thinking and the ability to notice details (recognizing behavior patterns and rules for the aesthetics of element sequences).',
            'Advanced understanding of combinatorics (calculating precise numbers of combinations under given conditions, utilizing permutations and combinations with or without repetition).',
            'Strong grasp of probability (calculating expectations and probabilities of events conditioned on previous states) and a continuous commitment to improving in these areas.',
        ],
        list3: [
            `Full time contract;`,
            'Modern working tools and equipment.',
            '20 days of paid vacation per year.',
            'Monthly allowance for transportation.',
            'Fun work start-up environment.',
            'Becoming part of a team where you can make a difference/impact from day one.',
            'Many other privileges and benefits.'
        ],
    },
};

export default dataJobs;