import React from 'react';
import { Link } from 'react-router-dom'
import ScrollToTop from '../../ScrollToTop';

const Montono = (props) => {

    const data = props.data;

    return (
        <section className="tf-section montono">
            <div className="container-fluid">
                <div className="row">
                    {
                        data.slice(0, 8).map((data, index) => (
                            <div key={index} className="col-xl-3 col-md-6">
                                <div className="image-box shw" data-aos="fade-up">
                                    <img src={data.img} alt="Monteno" />
                                    <div className="image-box__title">
                                        <Link to={`/games/${data.id}`} className="h6 fill-div" onClick={ScrollToTop}>More Info</Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </section>
    );
}

export default Montono;