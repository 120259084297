
import Home01 from './Home01';
import AboutUs from './AboutUs';
import Games from './Games';
import RoadMap from './RoadMap';
import Contact from './Contact';
import Career from './Career';
import Jobs from './Jobs';
import { Navigate } from 'react-router-dom';

const routes = [
  { path: '/', component: <Home01 /> },
  { path: '/about', component: <AboutUs /> },
  { path: '/games/:id', component: <Games /> },
  { path: '/games', component: <Games /> },
  { path: '/road-map', component: <RoadMap /> },
  { path: '/contact', component: <Contact /> },
  { path: '/career', component: <Career /> },
  { path: '/career/:id', component: <Jobs /> },
  { path: '/*', component: <Navigate from="*" to="/" /> },
]

export default routes;