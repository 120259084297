import React from 'react';
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import Action2 from '../components/layouts/Action2';
import dataCareer from '../assets/fake-data/data-career';
import CareerList from '../components/layouts/CareerList';

const Career = () => {


    return (
        <div className="games">
            <Header />
            <section className="tf-section montono" id='portfolio'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block-text center pd-16">
                                <h1 className="heading-bg" data-aos="fade-in"><span>JOBS</span></h1>
                                <h5 className="sub-title mb-10" data-aos="fade-up">Career</h5>
                                <h3 className="title mb-28" data-aos="fade-up">Choose your future career</h3>
                            </div>
                        </div>
                    </div>
                </div>
               <CareerList data={dataCareer} />
            </section>
            <Action2 />
            <Footer />
        </div>
    );
}

export default Career;
