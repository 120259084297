import React from 'react';
import { dataAction2 } from '../../assets/fake-data/data-action.js';


const Action2 = () => {


    return (
        <section className="tf-section mb-60 career-action pt-16">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="action__body_email" data-aos="fade-up">
                            <div className="block-text">
                                <h3 className="mb-13">{dataAction2.title}</h3>
                                <p className='fs-21 mb-7'> {dataAction2.desc} </p>
                                <p className="fs-21 mb-7 it-1">{dataAction2.note}</p>
                            </div>
                            <a className="btn-action style-2" href="mailto:career@redstone.rs">{dataAction2.buttonText}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Action2;
