import { React, useState } from 'react';
// import { Link } from 'react-router-dom';

import item from '../../assets/fake-data/item';

const About = () => {

    const [data] = useState(
        {
            subtitle: 'Our Story',
            title: 'Young and Quickly Growing',
            desc1: 'Founded in 2021 by industry veterans with passion for developing great games and software.',
            desc2: 'With expertise in building high-performance systems, REDSTONE is quickly establishing a growing presence on the market.',
        }
    );

    return (
        <section className="tf-section section-about">
            <div className="container">
                <div className="row reverse">
                    <div className="col-xl-7 col-md-12">
                        <div className="group-image">
                            <div className="left">
                                <div className="item bg-1 shw" style={{
                                    backgroundImage: `url(${item.storybg5})`,
                                    backgroundSize: 'cover'
                                }}><img src={item.story5} className="px-256" alt="icon" /></div>
                            </div>
                            <div className="right">
                                <div className="item bg-2 shw" style={{
                                    backgroundImage: `url(${item.storybg4})`,
                                    backgroundSize: 'cover'
                                }}><img src={item.story4} className="px-256" alt="icon" /></div>
                                <div className="item bg-3 shw" style={{
                                    backgroundImage: `url(${item.storybg6})`,
                                    backgroundSize: 'cover'
                                }}><img src={item.story6} className="px-256" alt="icon" /></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-md-12">
                        <div className="block-text pt-12">
                            <h5 className="sub-title mb-10" data-aos="fade-up">{data.subtitle}</h5>
                            <h3 className="title mb-58" data-aos="fade-up">{data.title}</h3>
                            <p className="fs-21 mb-33" data-aos="fade-up">{data.desc1}</p>
                            <p className="fs-18 line-h17 mb-41" data-aos="fade-up">{data.desc2}</p>
                            {/* <Link to="/about" className="btn-action style-2"  data-aos="fade-up">More About Us</Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;