import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';

import logo from '../../assets/fake-data/logo';

const Footer = () => {

	const [isVisible, setIsVisible] = useState(false);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	};

	useEffect(() => {
		const toggleVisibility = () => {
			if (window.pageYOffset > 500) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", toggleVisibility);

		return () => window.removeEventListener("scroll", toggleVisibility);
	}, []);

	return (
		<>
			<footer className="footer">
				<div className="container">
					<div className="row">
						<div className="footer__body">
							<Link to="/">
								<img src={logo} alt="Monteno" data-aos="fade-down" style={{ width: "220px" }} />
							</Link>

							<p className="desc fs-18" data-aos="fade-up">
								Do you want to keep in touch and stay up to date on all of our latest news and game releases?
								<br />
								Follow us on social media:
							</p>
							<ul className="social">
								<li data-aos="fade-up"><a rel="norefferer noreferrer" className="footer-pd" target="_blank" href="https://www.linkedin.com/company/redstone-rs/ "><i className="fab fa-linkedin-in"></i></a></li>
								<li data-aos="fade-up"><a rel="norefferer noreferrer" className="footer-pd" target="_blank" href="https://www.instagram.com/redstone.rs/"><i className="fab fa-instagram"></i></a></li>
								<li data-aos="fade-up"><a rel="norefferer noreferrer" className="footer-pd" target="_blank" href="https://www.facebook.com/redstonegamestudio"><i className="fab fa-facebook-f"></i></a></li>
								{/* <li data-aos="fade-up"><a to="#"><i className="fab fa-twitter"></i></a></li> */}
								{/* <li data-aos="fade-up"><a to="#"><i className="fab fa-youtube"></i></a></li> */}
							</ul>
						</div>
						<div className="footer_bottom">
							<p className="fs-16">Red Stone Gaming d.o.o. - Copyright © 2023, All Rights Reserved.</p>
							{/* <ul>
								<li><Link to="#">Terms & Condition</Link></li>
								<li><Link to="#">Privacy Policy</Link></li>
								<li><Link to="#">Cookie Policy</Link></li>
							</ul> */}
						</div>
					</div>
				</div>
			</footer>
			{
				isVisible &&
				<Link onClick={scrollToTop} to='#' id="scroll-top" className="shw"></Link>
			}</>

	);
};

export default Footer;