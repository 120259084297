import React from 'react';
import { Link } from 'react-router-dom'

const Portfolio = (props) => {

    const data = props.data;

    return (
        <section className="tf-section porfolio">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="block-text center">
                            <h1 className="heading-bg" data-aos="fade-in"><span>PLAY</span></h1>
                            <h5 className="sub-title mb-10" data-aos="fade-up">Games Portfolio</h5>
                            <h3 className="title mb-28" data-aos="fade-up">Our Quickly <br /> Growing Catalog</h3>
                        </div>
                    </div>
                </div>
                <div className="row mt-61">
                    {
                        data.map((data, index) => (
                            <div key={index} className="col-md-6">
                                <div className="icon-box bg-2 shw" data-aos="fade-up">
                                    <div className="icon shw" style={{ backgroundImage: `url(${data.profile})`, backgroundSize: "cover" }}>
                                        {/* <img src={data.img} alt="Monteno" /> */}
                                    </div>
                                    <div className="content portfolio-c">
                                        <p className="fs-nm-24 color-main">{data.step}</p>
                                        <p className="h4 fs-36">{data.title}</p>
                                        <p className="fs-18">{data.desc} </p>

                                        <Link to={`/games/${data.id}`} className="btn-action style-2">Play</Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

export default Portfolio;